<template>
  <XyeaFormEdit
    icon="account-outline"
    url="users/"
    entityName="User"
    backRoute="users"
    :form="form"
    :id="id"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
  >
    <b-field label="First Name" horizontal>
      <b-input v-model="form.firstName" required v-focus />
    </b-field>
    <b-field label="Last Name" horizontal>
      <b-input v-model="form.lastName" required />
    </b-field>
    <b-field label="Email" horizontal>
      <b-input v-model="form.email" type="email" required />
    </b-field>
    <b-field label="Role" horizontal>
      <RoleSelect v-model="form.roleId" required />
    </b-field>
    <SelectLocation v-model="form.locationId" label="Main Location" required />
    <SelectLocation v-model="form.locationId2" label="Secondary Location" />
    <SelectLocation v-model="form.locationId3" label="Other Location" />
  </XyeaFormEdit>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import SelectLocation from "@/components/SelectLocation.vue";
import RoleSelect from "@/components/RoleSelect.vue";
import CheckDirty from "@/mixins/checkDirty";

export default {
  mixins: [CheckDirty],
  components: {
    XyeaFormEdit,
    SelectLocation,
    RoleSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      locationNameReadOnly: null,
      form: {
        locationId: null,
        firstName: null,
        lastName: null
      }
    };
  }
};
</script>
