<template>
  <div class="column">
    <b-field horizontal label="Role">
      <!-- Nested b-field prevents gaps and alignment issues -->
      <b-field>
        <RoleSelect :value="value" @input="v => $emit('input', v)" :includeAll="true" />
        <ClearSearch @clearSearch="$emit('clearSearch')" />
      </b-field>
    </b-field>
  </div>
</template>

<script>
import RoleSelect from "@/components/RoleSelect";
import ClearSearch from "@/components/ClearSearch.vue";

export default {
  components: { RoleSelect, ClearSearch },
  props: {
    value: { type: Number }
  }
};
</script>
