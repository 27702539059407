<template>
  <section class="section is-main-section">
    <div class="box">
      <div class="columns">
        <div class="column">
          <SelectLocation v-model="search.locationId" />
        </div>
        <div class="column">
          <b-field horizontal label="Name / Email" class="label-long">
            <b-input v-model="search.name" />
          </b-field>
        </div>
        <role-search-n-clear v-model="search.roleId" @clearSearch="clearSearch" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <ContextTableCard
          title="Users"
          icon="account-multiple-outline"
          :header-icons="headerIcons"
          :tableData="tableData"
          :sortField="search.sortField"
          :sortOrder="search.sortOrder"
          :page="search.page"
          :total="total"
          @refreshData="debounceRefreshData"
          @page-change="pageChange"
          @sort="sort"
          v-model="selected"
          @headerIconClicked="headerIconClicked"
          @contextDefaultClicked="editUser"
          :menuOptions="contextOptions"
          @menu-clicked="contextMenuClicked"
          :isLoading="isLoading"
          :checkable="true"
          @check="multipleSelected"
        >
          <b-table-column label="Surname" field="lastname" sortable v-slot="props">
            {{ props.row.lastName }}
          </b-table-column>
          <b-table-column label="First Name" field="firstname" sortable v-slot="props">
            {{ props.row.firstName }}
          </b-table-column>
          <b-table-column label="Email" field="email" sortable v-slot="props">
            {{ props.row.email }}
          </b-table-column>
          <b-table-column label="Location" field="location" sortable v-slot="props">
            {{ locationNameLookup(props.row.locationId, props.row.location, true)
            }}{{ locationNameLookup(props.row.locationId2, props.row.location2, true, ", ")
            }}{{ locationNameLookup(props.row.locationId3, props.row.location3, true, ", ") }}
          </b-table-column>
          <b-table-column label="Main Role" field="role" sortable v-slot="props">
            {{ props.row.role }}
          </b-table-column>
        </ContextTableCard>
      </div>
    </div>
  </section>
</template>

<script>
import SelectLocation from "@/components/SelectLocation.vue";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import RoleSearchNClear from "@/components/RoleSearchNClear.vue";
import RestPasswordDialog from "@/components/dialogs/RestPasswordDialog.vue";

import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  components: { SelectLocation, ContextTableCard, RoleSearchNClear },
  mixins: [ContextTableMixin],
  data() {
    const url = "users";
    const savedSearch = this.$store.getters["search/savedSearch"](url);

    return {
      url: url,
      entity: "Users",
      headerIcons: [IconsMixin.headerIconAdd("User"), IconsMixin.headerIconRefresh()],
      multipleRows: [],
      search: {
        locationId: savedSearch.locationId,
        name: savedSearch.name,
        roleId: savedSearch.roleId,
        page: savedSearch.page ?? 1,
        sortField: savedSearch.sortField ?? "lastname",
        sortOrder: savedSearch.sortOrder ?? "asc"
      }
    };
  },
  computed: {
    contextOptions() {
      var options = [{ text: "Edit", event: "edit-user" }];
      if (this.selected?.roleLevel >= 50) {
        options.push({ text: "Send Password Reset Email for " + this.selected.email, event: "password-email" });
      } else {
        options.push({ text: "Reset Password for " + this.selected.email, event: "password-reset" });
      }

      if (this.multipleRows.length) {
        options.push({ text: "Send Multiple (" + this.multipleRows.length + ") Password Reset Emails", event: "password-reset-multiple" });
      }
      return options;
    }
  },
  methods: {
    async headerIconClicked(iconName) {
      switch (iconName) {
        case "add":
          this.$router.push({ name: "user.new" });
          break;
      }
    },
    editUser() {
      if (this.selected.id) this.$router.push({ name: "user.edit", params: { id: this.selected.id } });
    },
    contextMenuClicked(event) {
      switch (event) {
        case "edit-user":
          this.editUser();
          break;
        case "password-email":
          this.$http
            .post("authenticate/forgotpassword", { email: this.selected.email })
            .then(() => this.$buefy.snackbar.open({ message: "Password Reset Email sent", queue: false }))
            .catch(e => this.$alerts.showErrorAlert(e, "Error Sending Password Reset"));
          break;
        case "password-reset":
          if (this.selected.id) {
            this.$buefy.modal.open({
              parent: this,
              component: RestPasswordDialog,
              hasModalCard: true,
              props: { user: this.selected }
            });
          }
          break;
        case "password-reset-multiple":
          this.$http
            .post("authenticate/forgotpassword", { emails: this.multipleRows.map(r => r.email) })
            .then(() => this.$buefy.snackbar.open({ message: this.multipleRows.length + " Password Reset Emails sent", queue: false }))
            .catch(e => this.$alerts.showErrorAlert(e, "Error Sending Password Reset Emails"));
          break;
      }
    },
    multipleSelected(checkedList, row) {
      this.multipleRows = checkedList;
    },
    clearSearch() {
      this.search.locationId = null;
      this.search.name = null;
      this.search.roleId = null;
    }
  }
};
</script>
