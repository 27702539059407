<template>
  <div>
    <XyeaFormEdit
      icon="passwordReset"
      url="password/"
      entityName="Password"
      :form="form"
      @close="$emit('close')"
      @add="refresh"
      @update="refresh"
      :dialog="true"
      :loadOnOpen="false"
      :saveBtnDisabled="!validPassword"
    >
      <section>
        <b-field label="E-mail Address">
          <b-input ref="email" type="email" name="email" v-model="form.email" disabled />
        </b-field>
        <PasswordStrength v-model="form.password" @isValid="(v) => (validPassword = v)" autofocus />
      </section>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import PasswordStrength from "@/components/PasswordStrength";
import PermissionsEnum from "@/enums/permissions";

export default {
  components: { XyeaFormEdit, PasswordStrength },
  props: { user: { type: Object } },
  data() {
    return {
      validPassword: false,
      form: this.getClearFormObject()
    };
  },
  computed: {
    readonly() {
      return !this.$store.getters.hasPermission(PermissionsEnum.ResidentUpdate);
    }
  },
  methods: {
    getClearFormObject() {
      return {
        id: null,
        email: null,
        password: null
      };
    },
    refresh() {
      this.$emit("refresh");
    }
  },
  mounted() {
    if (this.user?.id) {
      this.form = { ...this.user };
    }
  }
};
</script>
